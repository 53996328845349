import { usePage } from '@inertiajs/vue3';
import moment from 'moment/moment';

export function formatCurrency(value: number | bigint | Intl.StringNumericLiteral, currency: string) {
	currency = currency || 'EUR';
	// A little hacky here
	const locale = currency === 'EUR' ? 'it-IT' : 'en-US';
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
	});
	return formatter.format(value);
}

export function formatNumber(value: number | bigint | Intl.StringNumericLiteral, minDigits: number, maxDigits: number) {
	const formatter = new Intl.NumberFormat('it-IT', {
		style: 'decimal',
		minimumFractionDigits: minDigits || 2,
		maximumFractionDigits: maxDigits || 2,
	});
	return formatter.format(value);
}

export function formatDate(value: any, format: string) {
	if (value) {
		return moment(String(value)).format(format || 'YYYY-MM-DD hh:mm');
	}
	return value;
}

export function formattedDateTime(value: string | number | Date, locale?: string) {
	if (!value) return value;

	const page = usePage<{ locale: string }>();
	const pageLocale = page.props.locale || locale || navigator.language;

	const formatter = new Intl.DateTimeFormat(pageLocale, {
		// dateStyle: 'short',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		// hour12: false,
		// second: '2-digit',
		// timeStyle: 'short',
		// timeZone: 'UTC',
	});

	return formatter.format(new Date(value));
}

export function compareDates(date1: any, date2: any) {
	const firstDate = moment(date1).format('YYYY-MM-DD');
	const secondDate = moment(date2).format('YYYY-MM-DD');
	if (firstDate === secondDate) return 0;
	return firstDate < secondDate ? -1 : 1;
}

export default {
	formatCurrency,
	formatNumber,
	formatDate,
	formattedDateTime,
	compareDates,
};
